import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

export const LeaderCard = ({ leader, onClickMessage, onRemove }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        marginBottom: "4em",
        background: theme.palette.background.default,
        boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "300px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={leader?.image_id ? leader.image.path : "/assets/images/profiles-admin.svg"}
          alt=""
          width="100%"
        />
        {onRemove && (
          <Box sx={{ cursor: "pointer" }}>
            <img
              className="club-leader-remove"
              src="/assets/icons/cancel-icon.svg"
              alt=""
              onClick={() => onRemove(leader)}
            />
          </Box>
        )}
      </Box>
      <Box p={1}>
        <Typography fontWeight={900} fontSize={22} ml={4} mt={2}>
          {leader?.user?.first_name} {leader?.user?.last_name}
        </Typography>
        <Typography
          sx={{ "&:empty::before": { content: "''", display: "inline-block" } }}
          ml={4}
          variant="body2"
          color="text.secondary"
        >
          {leader?.title}
        </Typography>
      </Box>
      {onClickMessage && (
        <Box px={4} pt={1} pb={2}>
          <Grid item container justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              sx={{
                borderRadius: 50,
                color: "white",
              }}
              onClick={() => onClickMessage(leader)}
            >
              Message
            </Button>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
